body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: hsl(200, 40%, 25%);
}

@import url("https://fonts.googleapis.com/css2?family=JetBrains+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

@font-face {
  font-family: BNsixseven;
  src: url("./fonts/BN-67/BN-67.9010-03.ttf");
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*
  1. Use a more-intuitive box-sizing model.
*/
*,
*::before,
*::after {
  box-sizing: border-box;
}

/*
  2. Remove default margin
*/
* {
  margin: 0;
}

/*
  3. Allow percentage-based heights in the application
*/
html,
body {
  height: 100%;
}

/*
  Typographic tweaks!
  4. Add accessible line-height
  5. Improve text rendering
*/
body {
  -webkit-font-smoothing: antialiased;
}

/*
  6. Improve media defaults
*/
img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

/*
  7. Remove built-in form typography styles
*/
input,
button,
textarea,
select {
  font: inherit;
}

/*
  8. Avoid text overflows
*/
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

/*
  9. Create a root stacking context
*/
#root,
#__next {
  isolation: isolate;
}

/* Zaks CSS */

a {
  color: rgb(49, 49, 49);
  text-decoration: underline;
  cursor: pointer !important;
  transition: color 0.3s, cursor 0.3s;
}

a:hover {
  cursor: pointer !important;
  /* color: rgb(255, 255, 255); */
}

p {
  font-family: "JetBrains Mono";
  line-height: 1.5;
  font-size: 14px; /* Change the value to your desired size */
}

h1 {
  font-family: "BNsixseven";
  font-size: 75px;
}

h2 {
  font-family: "JetBrains Mono";
  line-height: 1.5;
  font-size: 26px; /* Change the value to your desired size */
}

h3 {
  font-family: "JetBrains Mono";
  line-height: 1.5;
  font-size: 16px; /* Change the value to your desired size */
}

li {
  font-family: "JetBrains Mono";
  line-height: 1.5;
  font-size: 14px; /* Change the value to your desired size */
}

hr {
  color: white;
}
