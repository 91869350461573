.carousel {
  position: relative; /* Add position: relative */
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 600px; /* Set a constant height */

  top: 100px;
}

.active {
  border-color: #aaa;
}

.prevButton,
.nextButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #ccc;
  color: #fff;
  border: none;
  height: 70px;
  width: 32px;
  padding: 10px;
  font-size: 24px;
  transition: background-color 0.3s ease;
  z-index: 10; /* Bring the arrows to the front */
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.prevButton {
  left: 10px;
  border-radius: 10px 0px 0px 10px;
}

.nextButton {
  right: 10px;
  border-radius: 0px 10px 10px 0px;
}

.prevButton:hover,
.nextButton:hover {
  background-color: #aaa;
  cursor: pointer;
}

.imgContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;

  width: 100%;
  height: 100%;
  margin-bottom: 20px;
}

.carouselImg {
  width: auto; /* Set width to auto */

  object-fit: contain; /* Maintain aspect ratio */
  border: 2px solid transparent;
  transition: border-color 0.3s ease;
  margin: 0 auto; /* Add this line */
}

.smallImages {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 20px;
  /* margin-top: 20px; */
}

.smallImg {
  width: 100px;
  height: 100px;
  object-fit: contain;
  cursor: pointer;
}

.selectedSmallImg {
  width: 110px;
  height: 110px;
  object-fit: contain;
  outline: 2px solid #000;
}

.container {
  width: fit-content;
  margin: 0 auto; /* Center the carousel horizontally */
}

.fullScreenOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.fullScreenImage {
  max-width: 95%;
  max-height: 95%;
  object-fit: contain;
  border: 2px solid transparent;
}

.fullScreenImgContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-bottom: 20px;
}

.svgright {
  position: absolute;
  z-index: 100;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  filter: drop-shadow(1px 2px 1px rgba(0, 0, 0, 0.25));
}
.svgleft {
  position: absolute;
  z-index: 100;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%) scaleX(-1);

  filter: drop-shadow(1px 2px 1px rgba(0, 0, 0, 0.25));
}
