.card {
  position: relative;
  background-color: rgb(159, 105, 105);
  /* width: 778px; */
  height: 110px;
  margin-bottom: 18px;
  border-radius: 5px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
}

.date {
  position: absolute;
  top: 10px;
  right: 10px;

  color: #dddddd;
  text-shadow: 1px 2px 2px rgba(0, 0, 0, 0.25);
  font-family: "BNsixseven";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 128.5%; /* 25.7px */
  letter-spacing: 7.7px;
}

.title {
  position: absolute;
  bottom: 10px;
  left: 10px;
  color: #dddddd;
  text-shadow: 1px 2px 2px rgba(0, 0, 0, 0.25);
  font-family: JetBrains Mono;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 128.5%; /* 25.7px */
  letter-spacing: -0.9px;
}

@media (max-width: 600px) {
  .card {
    height: 240px;
  }
  .date {
    font-size: 16px;
  }
  .title {
    font-size: 18px;
  }
}
