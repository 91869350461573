.App {
  position: fixed;
  text-align: center;
  z-index: 0;
  width: 100%;
  height: 100%;
  /* display: flex; */
  /* flex-direction: column; */
  /* align-items: center; */
}
