.entries {
  position: relative;

  width: 60%;
  margin-left: 240px;
  top: 80px;
  text-align: left;

  /* padding: 20px; */
  padding-bottom: 50px;
  /* justify-content: center; */

  z-index: 49;
}

@media (max-width: 600px) {
  .entries {
    position: relative;
    margin-left: 10px;
    margin-right: 10px;
    width: unset;

    /* padding: 20px; */
    padding-bottom: 50px;
    justify-content: center;

    z-index: 49;
  }
}
