.drawerbox {
  position: fixed;
  z-index: 100;
  top: 60px;
  width: 220px;
}

.nav {
  display: flex;
  justify-content: flex-start;
}

.ul {
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  width: 100%;
  list-style: none;
  padding: 0;
  flex-direction: column;
}

.link {
  font-family: JetBrains Mono;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  text-align: left;
  color: #dddddd;
  padding-left: 30px;
  line-height: 176.5%; /* 28.24px */
  letter-spacing: -0.96px;
  /* border: 1px solid blue; */
  margin-bottom: 10px;
  text-shadow: 1px 3px 3px rgba(0, 0, 0, 0.3);
  box-shadow: inset 1px 0 10px rgba(0, 0, 0, 0.3);
  transition: box-shadow 0.3s, text-shadow 0.3s;
}

.link:hover {
  color: #ddd;
  /* background-color: #ffffff23; */
  text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);

  box-shadow: inset 0px 0 1px rgba(0, 0, 0, 0.3);
}

.link a {
  /* Reset link styles */
  color: inherit !important;
  text-decoration: none !important;
}

.burger {
  display: none;
}

.burgerbox {
  display: none;
}

.navToggle {
  display: none;
}
.navToggleLabel {
  display: inline-block;
  position: fixed;
  top: 5px;
  right: 5px;
  z-index: 100;
  cursor: pointer;
}

.navToggle:checked ~ .drawerbox {
  transform: translateX(0);
}

@media (max-width: 600px) {
  .burgerbox {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;

    width: 20%;
    right: 0px;
    top: 0px;
    height: 60px;
  }

  .burger {
    display: block;
    box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.25);
  }

  .drawerbox {
    position: fixed;
    z-index: 100;
    height: 70%;
    top: 60px;
    width: 150px;
    right: 0px;
    /* background-color: white; */
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
  }

  .link {
    font-size: 24px;
    color: #fcffe0;
    line-height: 200%; /* 28.24px */
    box-shadow: none;
    background-color: rgb(221, 70, 70);
    border-radius: 10px 0 0 10px;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.4);
  }
}

@media (min-width: 600px) {
  .navToggleLabel {
    display: none; /* Hide the toggle in larger screens */
  }

  /* Reset the transform for larger screens */
  .drawerbox {
    /* transform: none; */
    /* position: relative; */
    /* width: auto; */
    /* box-shadow: none; */
  }
}
