.page {
  position: relative;

  width: 80%;
  margin-left: 60px;
  top: 100px;
  text-align: left;
  max-width: 1000px;

  justify-content: center;
  color: white;
  z-index: 49;
}

.date {
  position: absolute;
  z-index: 50;
  top: 10px;
  right: 10px;

  color: #dddddd;
  text-shadow: 1px 2px 2px rgba(0, 0, 0, 0.25);
  font-family: "BNsixseven";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 128.5%; /* 25.7px */
  letter-spacing: 7.7px;
}
.article {
  position: relative;

  top: 150px;
  text-align: left;

  justify-content: center;
  color: white;
  z-index: 49;
}

@media (max-width: 600px) {
  .page {
    margin-left: 10px;
    margin-right: 10px;
    width: unset;
  }
  .article {
    padding-bottom: 100px;
  }
}
