.box {
  position: fixed;
  display: flex;
  /* background-color: hsl(351, 100%, 37%); */
  width: 220px;
  height: 60px;
  align-items: center;

  z-index: 100;
  top: 0;
  left: 0;
}

.titleheader {
  font-family: "BNsixseven";
  color: #ebebeb;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -1.6px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: text-shadow 0.3s, transform 0.3s;
}

.titleheader:hover {
  transform: translate(-50%, -52%);
  text-shadow: 3px 3px 3px rgba(0, 0, 0, 0.25);
}

.title {
  color: rgb(230, 230, 230);
}

@media (max-width: 600px) {
  .box {
    width: 80%;
  }

  .titleheader {
    top: unset;
    left: unset;
    transform: unset;
    left: 20px;
  }
}
