.cv {
  position: relative;
  background-color: rgb(213, 213, 213);
  width: 80%;
  margin-left: 240px;
  top: 80px;
  text-align: left;
  max-width: 900px;
  padding: 20px;
  padding-bottom: 50px;
  justify-content: center;
  color: rgb(111, 111, 111);
  border-radius: 5px;

  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.25);
}

.filler {
  height: 200px;
}

@media (max-width: 600px) {
  .cv {
    position: relative;

    width: 100%;
    margin-left: 0%;
    top: 0px;
    text-align: left;
    padding-top: 100px;
    /* max-width: 700px; */
    color: rgb(111, 111, 111);
    /* justify-content: center; */
    border-radius: 0px;
  }

  .filler {
    height: 200px;
  }

  .cv p {
    /* color: red; */
    font-size: 10px;
  }

  .cv h3 {
    /* color: red; */
    font-size: 14px;
  }

  .cv li {
    /* color: red; */
    font-size: 11px;
  }
}
