.languagetoggle {
  position: fixed;
  top: 0px;
  right: 20px;
  width: 90px;
  height: 32px;
  display: flex;
  justify-content: space-between;
  border-radius: 0px 0px 10px 10px;
  /* background-color: #898989; */
  /* padding: 5px; */
  transition: height 0.3s;
}

.languagetoggle:hover {
  height: 34px;
}

.languageoption {
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: center;
  cursor: pointer;

  transition: background-color 0.3s ease, color 0.3s ease;

  align-items: center;
  justify-content: center;
}

.text {
  position: absolute;
  /* position: absolute; */
  /* margin-top: 5px; */
  font-family: BNsixseven;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  /* line-height: normal; */
  bottom: 0px;
}

.active {
  background-color: black;
  color: white;
}

.inactive {
  background-color: rgb(224, 224, 224);
  color: black;
  text-shadow: 1px 2px 1px rgba(0, 0, 0, 0.25);
  transition: text-shadow 0.3s;
}

.inactive:hover {
  /* background-color: rgb(210, 210, 210); */
  text-shadow: 3px 3px 3px rgba(0, 0, 0, 0.3);
}

.polishlanguage {
  border-radius: 0px 0px 10px 0px;
}

.englishlanguage {
  border-radius: 0px 0px 0px 10px;
}

@media (max-width: 600px) {
  /* Styles for devices with a maximum width of 600px (phones) */

  .languagetoggle {
    z-index: 100;
    position: fixed;
    top: unset;
    right: unset;
    bottom: 0px;
    left: 20px;
    width: 90px;
    height: 38px;
    display: flex;
    justify-content: space-between;
    border-radius: 10px 10px 0px 0px;
  }

  .languageoption {
    flex-direction: row;
  }

  .polishlanguage {
    border-radius: 0px 10px 0px 0px;
  }

  .englishlanguage {
    border-radius: 10px 0px 0px 0px;
  }
}
